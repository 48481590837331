import React from 'react';

import { graphql } from 'gatsby';

import { EmailLinkSignInProvider } from '../context/email-link-sign-in-provider';

import EmailLinkAuthPage from '../components/email-link-auth/email-link-auth-page';

export const query = graphql`
  query EmailLinkSignInPageQuery($pageId: String!, $breakpoints: [Int!]) {
    kalansoPage(pageId: { eq: $pageId }) {
      ...KalansoFullPageFragment
    }
  }
`;

const EmailLinkSignInTemplate = (props) => {
  return (
    <EmailLinkSignInProvider {...props}>
      <EmailLinkAuthPage {...props} />
    </EmailLinkSignInProvider>
  );
};

export default EmailLinkSignInTemplate;
