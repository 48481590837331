import React, { useEffect } from 'react';

import LoadingScreen from '../shared/loading-screen';

import { useActor } from '@xstate/react';

import { useLeadMachine } from '../../context/lead-machine-provider';
import { useAuth } from '../../context/firebase-auth-provider';
import { useLearningHouse } from '../../context/learning-house-provider';

const DEFAULT_LOADING_MESSAGE =
  'Welcome back! Please wait while we finish signing you in...';

const EmailLinkAuthPage = ({ data }) => {
  const { kalansoPage: page } = data;
  const { packId, funnelId } = page;

  const { user } = useAuth();
  const { visitor } = useLearningHouse();

  const service = useLeadMachine();

  const [state, send] = useActor(service);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const smid = searchParams.get('smid');
    const formData = {
      pkid: packId,
      fnid: funnelId,
      esurl: window.location.href,
    };
    const captureData = { smid };

    if (smid && user) {
      send({
        type: 'TRANSITION',
        session: {
          user,
          visitor,
        },
        page,
        formData,
        captureData,
      });
    }
  }, [send, packId, funnelId, user, visitor]);

  return (
    <LoadingScreen
      page={page}
      error={state.context.error}
      text={DEFAULT_LOADING_MESSAGE}
    />
  );
};

export default EmailLinkAuthPage;
