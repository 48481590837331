import React, { createContext, useState, useEffect } from 'react';

import { useAuth } from './firebase-auth-provider';

import LoadingScreen from '../components/shared/loading-screen';

export const EmailLinkSignInContext = createContext(null);

export const EmailLinkSignInProvider = ({ data, children }) => {
  const { kalansoPage = {} } = data;
  const [hijack, setHijack] = useState(false);

  const auth = useAuth();

  const { synchronized, user, error } = auth;

  useEffect(() => {
    auth.actions.signInWithEmailLink();
  }, []);

  useEffect(() => {
    if (synchronized) {
      if (user) {
        setHijack(false);
      }
    }
  }, [synchronized, user, error]);

  if (error) {
    return <LoadingScreen page={kalansoPage} error={error} />;
  }

  if (hijack) {
    return (
      <LoadingScreen
        page={kalansoPage}
        text="Please wait while we sign you in..."
      />
    );
  }

  return (
    <EmailLinkSignInContext.Provider value={null}>
      {children}
    </EmailLinkSignInContext.Provider>
  );
};

EmailLinkSignInProvider.propTypes = {};
